<template>
  <q-dialog
    ref="dialogRef"
    position="bottom"
    square
    @hide="onDialogHide"
  >
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <q-tabs
          v-model="mode"
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
          dense
        >
          <q-tab
            name="login"
            label="Login"
          />
          <q-tab
            name="register"
            label="Register"
          />
          <q-tab
            name="recover"
            label="Recover"
          />
        </q-tabs>
      </q-card-section>
      <q-card-section>
        <q-tab-panels
          v-model="mode"
          animated
        >
          <q-tab-panel
            name="login"
            class="no-scroll"
          >
            <q-form @submit="onLoginSubmit">
              <div class="q-gutter-y-md column">
                <q-input
                  v-model="credentials.username"
                  label="Email"
                  type="email"
                  :disable="authStore.pending"
                  :rules="[
                    (val) => !!val || 'Email is required.',
                    (val, rules) => rules.email(val) || 'Email must be valid.',
                  ]"
                />
                <q-input
                  v-model="credentials.password"
                  label="Password"
                  type="password"
                  :disable="authStore.pending"
                  :rules="[(val) => !!val || 'Password is required.']"
                />
                <q-btn
                  :loading="authStore.pending"
                  color="primary"
                  type="submit"
                  label="Login"
                  square
                />
                <q-btn
                  :disable="authStore.pending"
                  label="Forgot your password?"
                  outline
                  square
                  @click="mode = 'recover'"
                />
              </div>
            </q-form>
          </q-tab-panel>
          <q-tab-panel
            name="register"
            class="no-scroll"
          >
            <q-form @submit="onRegisterSubmit">
              <div class="q-gutter-y-md column">
                <q-input
                  v-model="registration.firstName"
                  label="First Name"
                  maxlength="15"
                  :disable="registering"
                  :rules="[
                    (val) => !!val || 'First Name is required.',
                    (val) =>
                      val.length <= 15
                      || 'First Name must be less or equal to 15 characters.',
                  ]"
                />
                <q-input
                  v-model="registration.lastName"
                  label="Last Name"
                  maxlength="25"
                  :disable="registering"
                  :rules="[
                    (val) => !!val || 'Last Name is required.',
                    (val) =>
                      val.length <= 25
                      || 'Last Name must be less or equal to 25 characters.',
                  ]"
                />
                <q-input
                  v-model="registration.email"
                  label="Email"
                  type="email"
                  :disable="registering"
                  :rules="[
                    (val) => !!val || 'Email is required.',
                    (val) =>
                      val.length <= 50
                      || 'Email must be less or equal to 50 characters.',
                    (val, rules) => rules.email(val) || 'Email must be valid.',
                  ]"
                />
                <!-- eslint-disable @stylistic/max-len -->
                <q-input
                  v-model="registration.password"
                  label="Password"
                  type="password"
                  :disable="registering"
                  :rules="[
                    (val) => !!val || 'Password is required.',
                    (val) =>
                      val.length >= 8
                      || 'Passwords must be at least 8 characters long.',
                    (val) =>
                      /(?=.*[A-Z])/.test(val)
                      || 'Passwords must have at least one uppercase (\'A\'-\'Z\').',
                    (val) =>
                      /(?=.*[a-z])/.test(val)
                      || 'Passwords must have at least one lowercase (\'a\'-\'z\').',
                    (val) =>
                      /(?=.*\d)/.test(val)
                      || 'Passwords must have at least one digit (\'0\'-\'9\').',
                    (val) =>
                      /([^a-zA-Z0-9])/.test(val)
                      || 'Passwords must have at least one non alphanumeric character like (\'!\', \'@\', \'#\', \'$\', \'%\', \'$\').',
                  ]"
                />
                <!-- eslint-enable @stylistic/max-len -->
                <q-input
                  v-model="registration.confirmPassword"
                  label="Confirm Password"
                  type="password"
                  :disable="registering"
                  :rules="[
                    (val) => !!val || 'Confirm Password is required.',
                    (val) =>
                      val == registration.password || 'Passwords do not match.',
                  ]"
                />
                <q-checkbox
                  v-model="subscribe"
                  :disable="registering"
                  color="secondary"
                  label="Be the first to know! Get our best deals delivered to your inbox!"
                  dense
                />
                <q-btn
                  color="primary"
                  type="submit"
                  label="Register"
                  :loading="registering"
                  square
                />
              </div>
            </q-form>
          </q-tab-panel>
          <q-tab-panel
            name="recover"
            class="no-scroll"
          >
            <div class="text-body1 text-weight-light text-center q-pa-sm">
              Begin the password recovery process.
            </div>
            <q-form @submit="onRecoverSubmit">
              <div class="q-gutter-y-md column">
                <q-input
                  v-model="recoveryEmail"
                  label="Email"
                  type="email"
                  :rules="[
                    (val) => !!val || 'Email is required.',
                    (val) =>
                      val.length <= 50
                      || 'Email must be less or equal to 50 characters.',
                    (val, rules) => rules.email(val) || 'Email must be valid.',
                  ]"
                />
                <q-btn
                  color="primary"
                  type="submit"
                  label="Recover"
                  :loading="recovering"
                  square
                />
              </div>
            </q-form>
          </q-tab-panel>
        </q-tab-panels>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';
import { whenever } from '@vueuse/core';
import { useDialogPluginComponent } from 'quasar';
import type { NavigateToOptions } from '#app/composables/router';
import type {
  AuthenticateRequest,
  RegisterRequest,
  UserResponse,
} from '~/types/ecommerce';

defineEmits([...useDialogPluginComponent.emits]);

const props = defineProps({
  redirect: {
    type: [Object as () => RouteLocationRaw, String],
  },
  navigation: {
    type: Object as PropType<NavigateToOptions>,
  },
});

const { $bus, $ecommerce } = useNuxtApp();
const { notify } = useQuasar();
const authStore = useAuthStore();
const userStore = useUserStore();
const cartStore = useCartStore();

const { dialogRef, onDialogHide } = useDialogPluginComponent();

const mode = ref('login');

const credentials = ref<AuthenticateRequest>({
  username: null,
  password: null,
});

const registration = ref<RegisterRequest>({
  email: null,
  firstName: null,
  lastName: null,
  password: null,
  confirmPassword: null,
});

const recoveryEmail = ref<string | null>(userStore.data?.email ?? null);
const subscribe = ref(true);
const registering = ref(false);
const recovering = ref(false);

const onLoginSubmit = () => authStore.login(credentials.value);

const onRegisterSubmit = async () => {
  registering.value = true;

  registration.value.cartId = cartStore.guestCartId;

  const response = await $ecommerce.fetch<UserResponse>('users', {
    method: 'POST',
    body: registration.value,
  }).catch((error) => {
    $ecommerce.handle(error);
    return undefined;
  });

  registering.value = false;

  if (!response) {
    return;
  }

  userStore.data = response;
  cartStore.guestCartId = null;

  $bus.emit('auth:register');

  if (subscribe.value) {
    $bus.emit('newsletter:opt-in', {
      email: registration.value.email as string,
      firstName: registration.value.firstName ?? undefined,
      lastName: registration.value.lastName ?? undefined,
    });
  }

  await authStore.login({
    username: registration.value.email,
    password: registration.value.password,
  });
};

const onRecoverSubmit = async () => {
  recovering.value = true;

  await $ecommerce.fetch('auth/password', {
    method: 'POST',
    body: {
      email: recoveryEmail.value,
      returnUrl: new URL('/?action=reset_password', useRequestURL()).toString(),
    },
  }).then(() => {
    notify({
      type: 'positive',
      message: 'You should receive an email with further instructions.',
    });

    onDialogHide();
  }).catch(error => $ecommerce.handle(error));

  recovering.value = false;
};

whenever(
  () => authStore.loggedIn,
  () => {
    onDialogHide();

    const error = useError();

    if (props.redirect) {
      navigateTo(props.redirect, props.navigation);
    }

    if (error) {
      clearError();
    }
  },
);
</script>
